import * as React from "react"
import { Link } from "gatsby"
import { useFooterMenuQuery } from "../../hooks/useFooterMenuQuery"

const date = new Date()
const dateYear = date.getFullYear()

const navigation = {
  solutions: [
    { name: "About Ergsense", href: "/about-ergsense" },
    { name: "Our Services", href: "/our-services" },
    { name: "Our Team", href: "/our-team" },
    { name: "Blog", href: "/blog" },
    { name: "End-User License Agreement", href: "/eula" },
  ],
  products: [
    {
      name: "Energy Sensing and Trending Tool",
      href: "/products/ergsense-energy-sensing-and-trending-est-tool",
    },
    {
      name: "D.Hat Product Development Kit",
      href: "/products/d-hat-product-development-kit",
    },
    {
      name: "D.Hat Add-on Module for PDK",
      href: "/products/d-hat-add-on-module-for-pdk",
    },
  ],
  social: [
    {
      name: "Twitter",
      href: "https://www.twitter.com/ergsense",
      icon: props => (
        <svg
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          {...props}
        >
          <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
        </svg>
      ),
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/ergsense/",
      icon: props => (
        <svg
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          {...props}
        >
          <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
        </svg>
      ),
    },
  ],
}

const Footer = () => {
  const { allWpPost } = useFooterMenuQuery()
  return (
    <footer className="bg-gray-800" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="xl:col-span-1">
            <div className="flex space-x-6">
              {navigation.social.map(item => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <p className="text-gray-300 mt-8">
              Need support or have a question?
            </p>
            <p className="text-gray-300 mt-2">
              <Link
                to="/contact-us"
                className="underline hover:no-underline text-gray-50"
                style={{ textUnderlineOffset: "3px" }}
              >
                Contact us
              </Link>{" "}
              anytime!
            </p>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Sitemap
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.solutions.map(item => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-base text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className="mt-8 text-sm font-semibold text-gray-400 tracking-wider uppercase md:mt-0">
                  Our Products
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.products.map(item => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-base text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-1">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Latest blog posts
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {allWpPost.nodes.map(item => (
                    <li key={item.id}>
                      <Link
                        to={`/blog${item.uri}`}
                        className="text-base text-gray-300 hover:text-white"
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8">
          <p className="text-base text-gray-400 xl:text-center">
            &copy; {new Date().getFullYear()} Ergsense, LLC. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
