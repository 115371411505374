import * as React from "react"
import { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { Link } from "gatsby"
import { MenuIcon, XIcon, ShoppingBagIcon } from "@heroicons/react/outline"
import logo from "../../images/ergsense-logo.svg"
import { useMenuQuery } from "../../hooks/useMenuQuery"

const Header = () => {
  const { wpMenu } = useMenuQuery()
  const menuItems = wpMenu.menuItems.nodes
  return (
    <div className="w-full sticky top-0 z-20">
      <Popover className="bg-white shadow-lg border-b-2 border-primary">
        <div className="flex justify-between items-center px-4 py-2 md:justify-start md:space-x-10 lg:px-24">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span className="sr-only">Ergsense</span>
              <img src={logo} alt="Ergsense logo" width={250} height={90} />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primaryDarker">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group
            as="nav"
            className="hidden md:flex space-x-10 items-center"
          >
            {menuItems.map(item => (
              <Link
                key={item.id}
                to={item.path}
                className="text-base font-medium text-gray-500 hover:text-secondary"
              >
                {item.label}
              </Link>
            ))}
          </Popover.Group>

          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <Link
              to="/store"
              style={{ fontSize: `1.17rem` }}
              className="ml-8 bg-primaryDarker whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-full shadow-sm font-bold text-white hover:bg-primary"
            >
              Store
              <svg
                className="ml-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 74 74"
                width="33"
                height="33"
                fill="white"
              >
                <text
                  transform="translate(31.995 -18.544)"
                  font-size="18"
                  fill="red"
                  font-family="ArialMT, Arial"
                >
                  4
                  <tspan x="-5.005" y="124">
                    14
                  </tspan>
                  <tspan x="-5.005" y="248">
                    24
                  </tspan>
                  <tspan x="-5.005" y="372">
                    34
                  </tspan>
                  <tspan x="-5.005" y="496">
                    44
                  </tspan>
                  <tspan x="-5.005" y="620">
                    54
                  </tspan>
                  <tspan x="-5.005" y="744">
                    64
                  </tspan>
                  <tspan x="-5.005" y="868">
                    74
                  </tspan>
                  <tspan x="-5.005" y="992">
                    84
                  </tspan>
                  <tspan x="-5.005" y="1116">
                    94
                  </tspan>
                  <tspan x="-10.011" y="1240">
                    104
                  </tspan>
                  <tspan x="-9.343" y="1364" letter-spacing="-.074em">
                    1
                  </tspan>
                  <tspan x="-.668" y="1364">
                    14
                  </tspan>
                  <tspan x="-10.011" y="1488">
                    124
                  </tspan>
                  <tspan x="-10.011" y="1612">
                    134
                  </tspan>
                  <tspan x="-10.011" y="1736">
                    144
                  </tspan>
                  <tspan x="-10.011" y="1860">
                    154
                  </tspan>
                  <tspan x="-10.011" y="1984">
                    164
                  </tspan>
                  <tspan x="-10.011" y="2108">
                    174
                  </tspan>
                  <tspan x="-10.011" y="2232">
                    184
                  </tspan>
                  <tspan x="-10.011" y="2356">
                    194
                  </tspan>
                  <tspan x="-10.011" y="2480">
                    204
                  </tspan>
                  <tspan x="-10.011" y="2604">
                    214
                  </tspan>
                  <tspan x="-10.011" y="2728">
                    224
                  </tspan>
                  <tspan x="-10.011" y="2852">
                    234
                  </tspan>
                  <tspan x="-10.011" y="2976">
                    244
                  </tspan>
                  <tspan x="-10.011" y="3100">
                    254
                  </tspan>
                  <tspan x="-10.011" y="3224">
                    264
                  </tspan>
                  <tspan x="-10.011" y="3348">
                    274
                  </tspan>
                  <tspan x="-10.011" y="3472">
                    284
                  </tspan>
                  <tspan x="-10.011" y="3596">
                    294
                  </tspan>
                </text>
                <g data-name="2 PT">
                  <path
                    stroke="white"
                    stroke-width="1"
                    d="M71 22H3a1 1 0 0 1-.908-1.419l6-13A1 1 0 0 1 9 7h56a1 1 0 0 1 .908.581l6 13A1 1 0 0 1 71 22ZM4.563 20h64.874L64.36 9H9.64Z"
                  />
                  <path
                    stroke="white"
                    stroke-width="1"
                    d="M7.857 34A5.864 5.864 0 0 1 2 28.143V21a1 1 0 0 1 1-1h9.714a1 1 0 0 1 1 1v7.143A5.864 5.864 0 0 1 7.857 34ZM4 22v6.143a3.857 3.857 0 0 0 7.714 0V22Z"
                  />
                  <path
                    stroke="white"
                    stroke-width="1"
                    d="M17.571 34a5.864 5.864 0 0 1-5.857-5.857V21a1 1 0 0 1 1-1h9.714a1 1 0 0 1 1 1v7.143A5.864 5.864 0 0 1 17.571 34Zm-3.857-12v6.143a3.857 3.857 0 0 0 7.714 0V22Z"
                  />
                  <path
                    stroke="white"
                    stroke-width="1"
                    d="M27.286 34a5.864 5.864 0 0 1-5.857-5.857V21a1 1 0 0 1 1-1h9.714a1 1 0 0 1 1 1v7.143A5.864 5.864 0 0 1 27.286 34Zm-3.857-12v6.143a3.857 3.857 0 0 0 7.714 0V22Z"
                  />
                  <path
                    stroke="white"
                    stroke-width="1"
                    d="M37 34a5.864 5.864 0 0 1-5.857-5.857V21a1 1 0 0 1 1-1h9.714a1 1 0 0 1 1 1v7.143A5.864 5.864 0 0 1 37 34Zm-3.857-12v6.143a3.857 3.857 0 0 0 7.714 0V22Z"
                  />
                  <path
                    stroke="white"
                    stroke-width="1"
                    d="M46.714 34a5.864 5.864 0 0 1-5.857-5.857V21a1 1 0 0 1 1-1h9.714a1 1 0 0 1 1 1v7.143A5.864 5.864 0 0 1 46.714 34Zm-3.857-12v6.143a3.857 3.857 0 0 0 7.714 0V22Z"
                  />
                  <path
                    stroke="white"
                    stroke-width="1"
                    d="M56.429 34a5.864 5.864 0 0 1-5.857-5.857V21a1 1 0 0 1 1-1h9.714a1 1 0 0 1 1 1v7.143A5.864 5.864 0 0 1 56.429 34Zm-3.858-12v6.143a3.857 3.857 0 0 0 7.714 0V22Z"
                  />
                  <path
                    stroke="white"
                    stroke-width="1"
                    d="M66.143 34a5.864 5.864 0 0 1-5.857-5.857V21a1 1 0 0 1 1-1H71a1 1 0 0 1 1 1v7.143A5.864 5.864 0 0 1 66.143 34Zm-3.857-12v6.143a3.857 3.857 0 0 0 7.714 0V22Z"
                  />
                  <path
                    stroke="white"
                    stroke-width="1"
                    d="M68 70H5.91a1 1 0 0 1-1-1V32h2v36H67V32h2v37a1 1 0 0 1-1 1Z"
                  />
                  <path
                    stroke="white"
                    stroke-width="1"
                    d="M60 70H43a1 1 0 0 1-1-1V41a1 1 0 0 1 1-1h17a1 1 0 0 1 1 1v28a1 1 0 0 1-1 1Zm-16-2h15V42H44ZM37 61.5H12a1 1 0 0 1-1-1v-20a1 1 0 0 1 1-1h25a1 1 0 0 1 1 1v20a1 1 0 0 1-1 1Zm-24-2h23v-18H13Z"
                  />
                </g>
              </svg>
            </Link>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <Popover.Button as={Link} to="/">
                    <img
                      src={logo}
                      width={250}
                      height={90}
                      alt="Ergsense logo"
                    />
                  </Popover.Button>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primaryDarker">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid grid-cols-1 gap-7">
                    {menuItems.map(item => (
                      <Popover.Button
                        as={Link}
                        key={item.id}
                        to={item.path}
                        className="-m-3 p-3 flex items-center justify-center rounded-lg hover:bg-gray-50"
                      >
                        <div className="ml-4 text-base font-medium text-gray-900">
                          {item.label}
                        </div>
                      </Popover.Button>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5">
                <div className="mt-6">
                  <Popover.Button
                    as={Link}
                    to="/store"
                    className="w-full flex items-center justify-center mt-4 px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primaryDarker hover:bg-primaryDarker20"
                  >
                    Store{" "}
                    <svg
                      className="ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 74 74"
                      width="33"
                      height="33"
                      fill="white"
                    >
                      <text
                        transform="translate(31.995 -18.544)"
                        font-size="18"
                        fill="red"
                        font-family="ArialMT, Arial"
                      >
                        4
                        <tspan x="-5.005" y="124">
                          14
                        </tspan>
                        <tspan x="-5.005" y="248">
                          24
                        </tspan>
                        <tspan x="-5.005" y="372">
                          34
                        </tspan>
                        <tspan x="-5.005" y="496">
                          44
                        </tspan>
                        <tspan x="-5.005" y="620">
                          54
                        </tspan>
                        <tspan x="-5.005" y="744">
                          64
                        </tspan>
                        <tspan x="-5.005" y="868">
                          74
                        </tspan>
                        <tspan x="-5.005" y="992">
                          84
                        </tspan>
                        <tspan x="-5.005" y="1116">
                          94
                        </tspan>
                        <tspan x="-10.011" y="1240">
                          104
                        </tspan>
                        <tspan x="-9.343" y="1364" letter-spacing="-.074em">
                          1
                        </tspan>
                        <tspan x="-.668" y="1364">
                          14
                        </tspan>
                        <tspan x="-10.011" y="1488">
                          124
                        </tspan>
                        <tspan x="-10.011" y="1612">
                          134
                        </tspan>
                        <tspan x="-10.011" y="1736">
                          144
                        </tspan>
                        <tspan x="-10.011" y="1860">
                          154
                        </tspan>
                        <tspan x="-10.011" y="1984">
                          164
                        </tspan>
                        <tspan x="-10.011" y="2108">
                          174
                        </tspan>
                        <tspan x="-10.011" y="2232">
                          184
                        </tspan>
                        <tspan x="-10.011" y="2356">
                          194
                        </tspan>
                        <tspan x="-10.011" y="2480">
                          204
                        </tspan>
                        <tspan x="-10.011" y="2604">
                          214
                        </tspan>
                        <tspan x="-10.011" y="2728">
                          224
                        </tspan>
                        <tspan x="-10.011" y="2852">
                          234
                        </tspan>
                        <tspan x="-10.011" y="2976">
                          244
                        </tspan>
                        <tspan x="-10.011" y="3100">
                          254
                        </tspan>
                        <tspan x="-10.011" y="3224">
                          264
                        </tspan>
                        <tspan x="-10.011" y="3348">
                          274
                        </tspan>
                        <tspan x="-10.011" y="3472">
                          284
                        </tspan>
                        <tspan x="-10.011" y="3596">
                          294
                        </tspan>
                      </text>
                      <g data-name="2 PT">
                        <path
                          stroke="white"
                          stroke-width="1"
                          d="M71 22H3a1 1 0 0 1-.908-1.419l6-13A1 1 0 0 1 9 7h56a1 1 0 0 1 .908.581l6 13A1 1 0 0 1 71 22ZM4.563 20h64.874L64.36 9H9.64Z"
                        />
                        <path
                          stroke="white"
                          stroke-width="1"
                          d="M7.857 34A5.864 5.864 0 0 1 2 28.143V21a1 1 0 0 1 1-1h9.714a1 1 0 0 1 1 1v7.143A5.864 5.864 0 0 1 7.857 34ZM4 22v6.143a3.857 3.857 0 0 0 7.714 0V22Z"
                        />
                        <path
                          stroke="white"
                          stroke-width="1"
                          d="M17.571 34a5.864 5.864 0 0 1-5.857-5.857V21a1 1 0 0 1 1-1h9.714a1 1 0 0 1 1 1v7.143A5.864 5.864 0 0 1 17.571 34Zm-3.857-12v6.143a3.857 3.857 0 0 0 7.714 0V22Z"
                        />
                        <path
                          stroke="white"
                          stroke-width="1"
                          d="M27.286 34a5.864 5.864 0 0 1-5.857-5.857V21a1 1 0 0 1 1-1h9.714a1 1 0 0 1 1 1v7.143A5.864 5.864 0 0 1 27.286 34Zm-3.857-12v6.143a3.857 3.857 0 0 0 7.714 0V22Z"
                        />
                        <path
                          stroke="white"
                          stroke-width="1"
                          d="M37 34a5.864 5.864 0 0 1-5.857-5.857V21a1 1 0 0 1 1-1h9.714a1 1 0 0 1 1 1v7.143A5.864 5.864 0 0 1 37 34Zm-3.857-12v6.143a3.857 3.857 0 0 0 7.714 0V22Z"
                        />
                        <path
                          stroke="white"
                          stroke-width="1"
                          d="M46.714 34a5.864 5.864 0 0 1-5.857-5.857V21a1 1 0 0 1 1-1h9.714a1 1 0 0 1 1 1v7.143A5.864 5.864 0 0 1 46.714 34Zm-3.857-12v6.143a3.857 3.857 0 0 0 7.714 0V22Z"
                        />
                        <path
                          stroke="white"
                          stroke-width="1"
                          d="M56.429 34a5.864 5.864 0 0 1-5.857-5.857V21a1 1 0 0 1 1-1h9.714a1 1 0 0 1 1 1v7.143A5.864 5.864 0 0 1 56.429 34Zm-3.858-12v6.143a3.857 3.857 0 0 0 7.714 0V22Z"
                        />
                        <path
                          stroke="white"
                          stroke-width="1"
                          d="M66.143 34a5.864 5.864 0 0 1-5.857-5.857V21a1 1 0 0 1 1-1H71a1 1 0 0 1 1 1v7.143A5.864 5.864 0 0 1 66.143 34Zm-3.857-12v6.143a3.857 3.857 0 0 0 7.714 0V22Z"
                        />
                        <path
                          stroke="white"
                          stroke-width="1"
                          d="M68 70H5.91a1 1 0 0 1-1-1V32h2v36H67V32h2v37a1 1 0 0 1-1 1Z"
                        />
                        <path
                          stroke="white"
                          stroke-width="1"
                          d="M60 70H43a1 1 0 0 1-1-1V41a1 1 0 0 1 1-1h17a1 1 0 0 1 1 1v28a1 1 0 0 1-1 1Zm-16-2h15V42H44ZM37 61.5H12a1 1 0 0 1-1-1v-20a1 1 0 0 1 1-1h25a1 1 0 0 1 1 1v20a1 1 0 0 1-1 1Zm-24-2h23v-18H13Z"
                        />
                      </g>
                    </svg>
                  </Popover.Button>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  )
}

export default Header
