import { useStaticQuery, graphql } from "gatsby"

export const useMenuQuery = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      wpMenu(name: { eq: "mainMenu" }) {
        menuItems {
          nodes {
            label
            path
            parentId
            id
          }
        }
      }
    }
  `)

  return data
}
