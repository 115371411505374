import React from "react"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import "../../styles/global.css"
import "./Layout.css"

const Layout = ({ children }) => {
  return (
    <div
      style={{ display: `flex`, minHeight: `100vh`, flexDirection: `column` }}
    >
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
